<template>
<div class="page-case">
  <comm-banner :text="$t('menu.case')" />
  <div class="container">
    <div class="case-box">
      <div class="case-title">
        <i class="icon icon-kj" />
        <span class="name">{{$t('pageHome.case.kj')}}</span>
        <span  class="icon-switch" :class="{show:showKJ===false}" @click="showKJ=!showKJ" />
      </div>
      <transition name="el-zoom-in-top">
        <case-list v-if="showKJ" ref="caseListKJ" :list="CaseKJ" @check="handlerCheck(['caseListXF','caseListYX'])"/>
      </transition>

      <div class="case-title">
        <i class="icon icon-xf" />
        <span class="name">{{$t('pageHome.case.xf')}}</span>
        <span  class="icon-switch" :class="{show:showXF===false}" @click="showXF=!showXF" />
      </div>
      <transition name="el-zoom-in-top">
        <case-list v-if="showXF"  ref="caseListXF" :list="CaseXF" @check="handlerCheck(['caseListKJ','caseListYX'])"/>
      </transition>

      <div class="case-title">
        <i class="icon icon-yx" />
        <span class="name">{{$t('pageHome.case.yx')}}</span>
        <span  class="icon-switch" :class="{show:showYX===false}" @click="showYX=!showYX" />
      </div>
      <transition name="el-zoom-in-top">
        <case-list v-if="showYX"  ref="caseListYX" :list="CaseYX" @check="handlerCheck(['caseListKJ','caseListXF'])"/>
      </transition>
    </div>
  </div>
</div>
</template>

<script>
import {CaseKJ,CaseXF,CaseYX} from '@/utils/const'
import CaseList from "../../components/CaseList";
import CommBanner from "@/components/CommBanner";
export default {
  name: "Case",
  components: {CommBanner, CaseList},
  data(){
    return {
      showKJ: true,
      showXF: true,
      showYX: true,
      CaseKJ,
      CaseXF,
      CaseYX
    }
  },
  methods:{
    handlerCheck(refs){
      refs.forEach((ref)=>{
        if(this.$refs[ref]){
          this.$refs[ref].restCheck()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-case {
  .case-box{
    padding-top: 64px;
    .case-title{
      position: relative;
      display: flex;
      border-bottom: 1px solid #CDCDCD;
      height: 64px;
      padding-bottom: 10px;
      align-items: center;
      margin-bottom: 32px;
      .icon{
        display: block;
        width: 64px;
        height: 64px;
        margin-right: 24px;
        &.icon-kj{
          background: url("./images/icon-title-kj.png") no-repeat center;
          background-size: 100% 100%;
        }
        &.icon-xf{
          background: url("./images/icon-title-xf.png") no-repeat center;
          background-size: 100% 100%;
        }
        &.icon-yx{
          background: url("./images/icon-title-yx.png") no-repeat center;
          background-size: 100% 100%;
        }
      }
      .name{
        font-weight: 600;
        font-size: 32px;
        color: #231916;
      }
      .icon-switch{
        position: absolute;
        right: 0;
        bottom: 11px;
        width: 16px;
        height: 12px;
        cursor: pointer;
        transition: all 0.5s;
        background: url("./images/icon-arrow-top.png") no-repeat;
        background-size: 100% 100%;
        &.show{
          transform: rotate(180deg);
        }
      }
    }
    .case-content{
      padding-top: 32px;
    }

  }
}
</style>
